import * as React from 'react';
import { Helmet } from 'react-helmet';
import CloudDetails from '../compontents/CloudDetails/CloudDetails';
import CloudText from '../compontents/CloudText/CloudText';
import ContactUsButton from '../compontents/ContactUsButton/ContactUsButton';
import Layout from '../compontents/Layout/Layout';
import { cloud_header_image, contact_us_container } from './cloud.module.scss';

type CloudPageProps = {
    location: any;
};

const CloudPage: React.FC<CloudPageProps> = ({ location }) => {
    return (
        <Layout location={location}>
            <div data-testid="cloud-container">
                <Helmet>
                    <title>Cloud - Applications and Solutions Streamlined!</title>
                </Helmet>
                <div className="d-flex justify-content-center">
                    <img src="../../images/pic5.png" className={cloud_header_image}></img>
                </div>
                <CloudText></CloudText>
                <CloudDetails></CloudDetails>
                <div
                    className={`${contact_us_container} d-flex align-items-center flex-column p-2`}
                    data-testid="get-in-touch-link">
                    <div>Contact us now to get your personalized solutions to your problems.</div>
                    <div>
                        <ContactUsButton />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default CloudPage;
