// extracted by mini-css-extract-plugin
export var azure = "CloudDetails-module--azure--t-sM8";
export var azure_devops = "CloudDetails-module--azure_devops--jsxOt";
export var azure_svg = "CloudDetails-module--azure_svg--wMwVE";
export var bit_bucket = "CloudDetails-module--bit_bucket--7cQOk";
export var bit_bucket_svg = "CloudDetails-module--bit_bucket_svg--EXxGh";
export var brands_display = "CloudDetails-module--brands_display--9tg-9";
export var cloud1 = "CloudDetails-module--cloud1--pPZG3";
export var cloud_flare = "CloudDetails-module--cloud_flare--uSZBa";
export var cloud_flare_svg = "CloudDetails-module--cloud_flare_svg--P3caI";
export var column_container = "CloudDetails-module--column_container--SCEa2";
export var container = "CloudDetails-module--container--BF+h8";
export var github = "CloudDetails-module--github--TzB-C";
export var higher = "CloudDetails-module--higher--ROw-R";
export var lower = "CloudDetails-module--lower--dlEMy";
export var svg = "CloudDetails-module--svg--P20Jx";
export var terraform = "CloudDetails-module--terraform--BuFQS";
export var terraform_svg = "CloudDetails-module--terraform_svg--qCWMJ";