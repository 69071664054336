import React from 'react';
import { cloud_text, cloud_text_container, cloud_text_inner_wrapper } from './CloudText.module.scss';

const CloudText: React.FC = () => {
    return (
        <div className={cloud_text_container} data-testid="cloud-text-container">
            <div className={cloud_text_inner_wrapper} data-testid="cloud-text-inner-wrapper">
                <h2 style={{ fontSize: '5rem' }}>The Cloud</h2>
                <p className={cloud_text}>
                    Your cloud infrastructure is imperative to your organization&apos;s success. At SquareHook, we help
                    you succeed whether it be Iaas, Paas or Saas, your service solution needs to be catered to your
                    company&apos;s needs. Be that through creating CI/CD pipelines, automated scaling or locking down
                    your security needs, our team of specialists will help achieve your goals.
                </p>
            </div>
        </div>
    );
};

export default CloudText;
