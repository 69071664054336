import React from 'react';
import AzureDevOpsLogo from '../../images/brands/Azure-devops.inline.svg';
import BitBucketLogo from '../../images/brands/Bitbucket-Logo.wine.inline.svg';
import CloudFlareLogo from '../../images/brands/Cloudflare-Logo.wine.inline.svg';
import GitHubLogo from '../../images/brands/GitHub-Logo.wine.inline.svg';
import MicrosoftAzureLogo from '../../images/brands/Microsoft_Azure-Logo.wine.inline.svg';
import Terraform from '../../images/brands/terraform.inline.svg';
import {
    azure,
    azure_devops,
    azure_svg,
    bit_bucket,
    bit_bucket_svg,
    brands_display,
    cloud1,
    cloud_flare,
    cloud_flare_svg,
    column_container,
    container,
    github,
    svg,
    terraform,
    terraform_svg,
} from './CloudDetails.module.scss';

const CloudDetails: React.FC = () => {
    return (
        <div className="cloud-details-container d-flex flex-direction-row" data-testid="cloud-details-container">
            <div className="row">
                <h2 className="d-flex justify-content-center p-2">Technologies We Specialize In</h2>
                <div className={`${brands_display} row d-block-md`}>
                    <div className={`${column_container} col-md-4 col-sm-12`}>
                        <div className={`${azure}   ${container}`}>
                            <MicrosoftAzureLogo className={`${svg} ${azure_svg}`} />
                        </div>
                    </div>
                    <div className={`${column_container} col-md-4 col-sm-12`}>
                        <div className={`${bit_bucket}  ${container}`}>
                            <BitBucketLogo className={`${svg} ${bit_bucket_svg}`} />
                        </div>
                    </div>
                    <div className={`${column_container} col-md-4 col-sm-12`}>
                        <div className={`${github}   ${container}`}>
                            <GitHubLogo className={`${svg}`} />
                        </div>
                    </div>
                    <div className={`${column_container} col-md-4 col-sm-12`}>
                        <div className={`${terraform}  ${container}`}>
                            <Terraform className={`${svg} ${terraform_svg}`} />
                        </div>
                    </div>
                    <div className={`${column_container} col-md-4 col-sm-12`}>
                        <div className={`${azure_devops}  ${container}`}>
                            <AzureDevOpsLogo className={`${svg}`} />
                        </div>
                    </div>
                    <div className={`${column_container} col-md-4 col-sm-12`}>
                        <div className={`${cloud_flare}  ${container}`}>
                            <CloudFlareLogo className={`${svg} ${cloud_flare_svg}`} />
                        </div>
                    </div>
                </div>
                <div className="image col-lg-8 col-md-4">
                    <img
                        src="../../images/cloud-computing-banner-background-smart-city.jpg"
                        className={`${cloud1} img-fluid`}
                        alt="Woman at Workstation"
                        data-testid="cloud-details-image"></img>
                </div>
                <div className="col-lg-4 col-md-8 p-4" data-testid="cloud-details-list">
                    <h2>Solutions</h2>
                    <ul>
                        <li>Lower business risk with security </li>
                        <li>Deploy globally </li>
                        <li>Cost savings </li>
                        <li>Build, manage & continuously deliver applications</li>
                        <li>Migrate to the cloud</li>
                        <li>Help with compliance Creating CI/CD pipeline </li>
                        <li>Cost audit </li>
                        <li>Security audit </li>
                        <li>Infrastructure as code with terraform & ARM templates </li>
                        <li>creating cloud infrastructure </li>
                        <li>creating multiple servers in multiple regions </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CloudDetails;
